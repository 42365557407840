import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const StyledImage = styled(Box)`
  box-shadow: ${({ theme }) => `0 12px 84px ${theme.colors.darkShadow}`};
  border-radius: 8px;
  overflow: hidden;
`;

const ImageCol = ({ image }) => (
  <Col
    lg="3"
    md="6"
    className="mb-4"
    data-aos="zoom-in"
    data-aos-duration="750"
    data-aos-once="true"
    data-aos-delay="50"
  >
    <StyledImage>
      <Img fluid={image} />
    </StyledImage>
  </Col>
);

const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => theme.colors.greyBackground};
  border-bottom: 1px solid #ededf4;
`;

const Content2 = () => {

  const data = useStaticQuery(graphql`
    query {
      phone_detail_worklogs: file(relativePath: { eq: "phone_detail_worklogs.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      phone_detail_schedule: file(relativePath: { eq: "phone_detail_schedule.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      phone_detail_time_tracker: file(relativePath: { eq: "phone_detail_time_tracker.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      phone_detail_hazards: file(relativePath: { eq: "phone_detail_hazards.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      phone_detail_attachments: file(relativePath: { eq: "phone_detail_attachments.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SectionStyled
        className="position-relative"
        py={["50px", null, "130px", null, "150px"]}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="9">
              <Box className="text-center" mb={[4, 5]}>
                <Title>
                  But not simplistic.
                </Title>
                <Text>
                  Access detail when you need it.
                </Text>
              </Box>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <ImageCol image={data.phone_detail_schedule.childImageSharp.fluid} />
            <ImageCol image={data.phone_detail_time_tracker.childImageSharp.fluid} />
            <ImageCol image={data.phone_detail_hazards.childImageSharp.fluid} />
            <ImageCol image={data.phone_detail_attachments.childImageSharp.fluid} />
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content2;
