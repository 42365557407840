import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      box-shadow: ${({ theme }) => `0 2px 8px ${theme.colors.shadow};`};
      &:hover {
        box-shadow: ${({ theme }) => `0 12px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
      mr={3}
    >
      <i className={`fa ${iconName}`}></i>
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const ContentCardCol = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Col
    lg="4"
    md="6"
    className="mb-4"
    data-aos="zoom-in"
    data-aos-duration="750"
    data-aos-once="true"
    data-aos-delay="50"
  >
    <ContentCard
      color={color}
      className={className}
      iconName={iconName}
      title={title}
      {...rest}
    />
  </Col>
);

const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => theme.colors.greyBackground};
  border-bottom: 1px solid #ededf4;
`;

const AppFeature = () => (
  <>
    <SectionStyled
      className="position-relative"
      py={["50px", null, "130px", null, "150px"]}
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg="9">
            <Box className="text-center" mb={[4, 5]}>
              <Title>
                But still powerful.
              </Title>
              <Text>
                Every screen in Opqo can be assembled from our growing library of process-driven widgets.
              </Text>
            </Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Assigned work"
            color="accentBlue"
            iconName="fa-inbox"
          />
          <ContentCardCol
            title="Work organization"
            color="accentGreen"
            iconName="fa-list"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Inspections"
            color="primary"
            iconName="fa-search"
          />
          <ContentCardCol
            title="Tasks"
            color="accentGreen"
            iconName="fa-check"
          />
          <ContentCardCol
            title="Child work orders"
            color="accentBlue"
            iconName="fa-sitemap"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Issue Reporting"
            color="accentOrange"
            iconName="fa-exclamation"
          />
          <ContentCardCol
            title="Quick Reporting"
            color="accentGreen"
            iconName="fa-file-alt"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Work map"
            color="accentGreen"
            iconName="fa-map-marked-alt"
          />
          <ContentCardCol
            title="ArcGIS&reg; map"
            color="accentBlue"
            iconName="fa-layer-group"
          />
          <ContentCardCol
            title="Linear"
            color="primary"
            iconName="fa-ruler"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Work schedule"
            color="accentYellow"
            iconName="fa-calendar-day"
          />
          <ContentCardCol
            title="Time tracking"
            color="accentBlue"
            iconName="fa-user-clock"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Meter Readings"
            color="accentGreen"
            iconName="fa-tachometer-alt"
          />
          <ContentCardCol
            title="Failure Reporting"
            color="accentOrange"
            iconName="fa-question"
          />
          <ContentCardCol
            title="Work Order Materials"
            color="accentYellow"
            iconName="fa-warehouse"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Work Logs"
            color="primary"
            iconName="fa-comments"
          />
          <ContentCardCol
            title="Attachments"
            color="accentBlue"
            iconName="fa-images"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Inventory Issue"
            color="accentBlue"
            iconName="fa-arrow-right"
          />
          <ContentCardCol
            title="Inventory Return"
            color="accentOrange"
            iconName="fa-arrow-left"
          />
          <ContentCardCol
            title="Inventory Pick and Stage"
            color="accentPurple"
            iconName="fa-shopping-cart"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Inventory Transfer"
            color="accentPurple"
            iconName="fa-arrow-down"
          />
          <ContentCardCol
            title="Inventory Receive"
            color="accentYellow"
            iconName="fa-inbox"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Inventory Count"
            color="accentGreen"
            iconName="fa-boxes"
          />
          <ContentCardCol
            title="Inventory Count Books"
            color="accentBlue"
            iconName="fa-book-open"
          />
          <ContentCardCol
            title="Inventory Browse"
            color="ash"
            iconName="fa-search"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Barcode scanning"
            color="accentOrange"
            iconName="fa-barcode"
          />
          <ContentCardCol
            title="Label Printing"
            color="accentGreen"
            iconName="fa-print"
          />
        </Row>
        <Row className="justify-content-center">
          <ContentCardCol
            title="Regularly improved"
            color="ash"
            iconName="fa-infinity"
            className="justify-content-center align-items-center"
          />
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default AppFeature;
